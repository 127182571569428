/* Card.css */
.card-container {
    border: 1px solid #ddd;
    text-align: center;
    display: inline-block;
    border-radius: 3px;
    padding: 1rem;
    margin: 0.5rem;
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background-color: #39A2DB; /* Match .App-header background color */
  }
  
  .card-container:hover {
    transform: scale(1.05);
  }
  
  .card-image {
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    display: inline-block;
  }
  
  .card-details h2,
  .card-details p {
    margin: 0.5rem 0;
  }
  