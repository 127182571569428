.my-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    font-size: 12px; /* Smaller font size */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px; /* Adjust width to be more compact */
}

.my-card img {
    width: 50px; /* Smaller image size */
    height: 50px;
    border-radius: 50%;
    margin-bottom: 5px;
}

.my-card p {
    margin: 2px 0;
}
