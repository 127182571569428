.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 /* Tab Container */
.Tabs {
  width: 90%;
  height: auto;
  min-height: 900px;
  background: #dbe2e3;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: #227387;
  border-radius: 2rem;
  @media (max-width: 769px) {
   padding: 2rem 0;
  }
 }
 
/* Tab Navigation */
ul.nav {
  width: 80%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #39A2DB;
  border-radius: 2rem;
  padding-left: 0px;
  @media (max-width: 768px) {
    width: 90%;
  }
}
ul.nav li {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}
ul.nav li:nth-child(n+2):nth-child(-n+8) {
  border-radius: 0;
}
ul.nav li:nth-child(9) {
  border-radius: 0;
  transition: all 0.7s;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}
ul.nav li.active {
  background: #39A2DB;
} 

/* First and Second Tab Styles */
.FirstTab p,
.SecondTab p {
  font-size: 2rem;
  text-align: center;
}
.my-card {
  background-color: #39A2DB;
  /* Other styles... */
}

.collapsible-table .nav {
  padding: 10px;
  margin: 5px 0;
  background-color: #39A2DB;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}

.collapsible-table .nav .content {
  padding: 10px;
  background-color: #dbe2e3;
  color: #227387;
  text-align: left;
}

.collapsible-table button {
  cursor: pointer;
  background-color: #f0f0f0;
  color: #227387;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  margin-left: 10px;
}

/* Main container for the collapsible table */
.CollapsibleTable {
  width: 90%;
  margin: 3.5rem auto 1.5rem;
  transition: all 0.3s ease;
}

/* Nav for the individual rows */
.collapsible-table .nav {
  padding: 10px;
  margin: 5px 0;
  background-color: #39A2DB;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}

/* Content for the collapsible rows */
.collapsible-table .nav .content {
  padding: 10px;
  background-color: #dbe2e3;
  color: #227387;
  text-align: left;
}

/* Button styles */
.collapsible-table button {
  cursor: pointer;
  background-color: #f0f0f0;
  color: #227387;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  margin-left: 10px;
}

/* Add these styles to your CSS file */

.CollapsibleTable {
  font-family: 'Arial, sans-serif'; /* Example font */
}

.CollapsibleTable h2 {
  font-family: 'Arial, sans-serif'; /* Example font */
}

.CollapsibleTable .nav {
  font-family: 'Arial, sans-serif'; /* Example font */
}

.CollapsibleTable .content {
  font-family: 'Arial, sans-serif'; /* Example font */
}

.classrooms-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}


.droppable-area {
  font-family: 'Arial, sans-serif';
  border: 2px solid gray;
  background-color: #dbe2e3;
  padding: 10px;
  margin: 10px;
  min-height: 100px; /* Ensures the droppable area is visible even if it's empty */
}

.classroom {
  flex: 1 1 48%; /* Adjust size to manage how many can fit per row, here it's roughly half to fit two per row */
  margin: 10px; /* Adds space between classrooms */
}

.spacer {
  height: 20px;
  min-height: 100px; /* Adjust the height as needed for visual space */
  width: 100%;
}

@media (max-width: 600px) {
  .date-picker-container {
      width: 100%;
      position: absolute;
      bottom: 0; /* Adjust based on your layout */
  }
}
