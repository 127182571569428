@import "https://use.fontawesome.com/releases/v5.1.0/css/all.css";

body, table, td, a {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

            body {
                height: 100% !important;
                margin: 0 !important;
                padding: 0 !important;
                width: 100% !important;
            }

            /* Removes spacing between tables in Outlook 2007+ */
            table, td {
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
            }

            img {
                border: 0;
                line-height: 100%;
                text-decoration: none;
                -ms-interpolation-mode: bicubic;
                /* Smoother rendering in IE */
            }

            table {
                border-collapse: collapse !important;
            }

            /* iOS Blue Links */
            a[x-apple-data-detectors] {
                color: inherit !important;
                text-decoration: none !important;
                font-size: inherit !important;
                font-family: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
            }

            /* Table fix for Outlook */
            table {
                border-collapse: separate;
            }

            .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td {
                line-height: 100%;
            }

            .ExternalClass {
                width: 100%;
            }

            .collapsibleSection {
                cursor: pointer;
                transition: 0.4s;
            }

            .collapsibleAssessment {
                cursor: pointer;
                transition: 0.4s;
            }

            .collapsibleDetails {
                cursor: pointer;
                transition: 0.4s;
            }

            /* Desktop */
            @media screen {
                .wrapper {
                    max-width: 1100px;
                }

                .table-max {
                    max-width: 1100px;
                }

                .table-max-assessments {
                    max-width: 985px !important;
                }
            }

            /* Desktop */
            @media screen and (max-width: 1350px) {
                .wrapper {
                    max-width: 1000px;
                }

                .table-max {
                    max-width: 1000px;
                }

                .table-max-assessments {
                    max-width: 885px;
                }
            }

            /* Mobile Styling */
            @media screen and (max-width: 525px) {
                .wrapper {
                    width: 100% !important;
                    max-width: 100% !important;
                }

                .hide-element {
                    display: none !important;
                }

                .no-padding {
                    padding: 0 !important;
                }

                .img-max {
                    max-width: 100% !important;
                    width: 100% !important;
                    height: auto !important;
                }

                .table-max {
                    width: 100% !important;
                }

                .mobile-btn-container {
                    margin: 0 auto;
                    width: 100% !important;
                }

                .mobile-btn {
                    padding: 15px !important;
                    border: 0 !important;
                    font-size: 16px !important;
                    display: block !important;
                }
            }

            /* iPads (landscape) Styling */
            @media handheld, all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
                .wrapper-ipad {
                    max-width: 280px !important;
                }

                .table-max-assessments {
                    max-width: 465px !important;
                }
            }

            /* iPads (portrait) Styling */
            @media handheld, all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
                .wrapper-ipad {
                    max-width: 280px !important;
                }

                .table-max-assessments {
                    max-width: 465px !important;
                }
            }

            .cellPadding {
                padding: 5px;
            }
