table {
  border-collapse: collapse;
}

td {
  border: 1px solid black;
  padding: 5px;
}

td:nth-of-type(2) {
  text-align: left;
}
