.compact-select {
    height: 30px;
    font-size: 14px;
}

.floating-save-button,
.floating-export-button {
    position: fixed !important;
    z-index: 1000 !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2) !important;
}

.floating-save-button {
    right: 20px !important;
    bottom: 60px !important;
    
}

.floating-export-button {
    right: 20px !important; /* Adjust as needed to prevent overlap */
    bottom: 20px !important;
    
}
