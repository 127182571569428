#invoice-page-container .invoice-page {
    background-color: white;
  }
  
  .images-container {
    background-color: white;
    display: flex;
    flex-direction: column;
   }
  
  .logo-container {
    background-color: white;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .line {
    background-color: white;
    width: 100%;
    border-top: 2px solid black;
    margin-top: 20px;
  }
  
  .bookings-table {
    width: 80%;
  }
  .bookings-container {
    display: flex;
    justify-content: center;
  }
  .bookings-table th,
  .bookings-table td {
    padding: 8px;
    text-align: left;
  }
  
  .bookings-table th:nth-child(1) {
    width: 40%;
  }
  
  .bookings-table th:nth-child(2),
  .bookings-table th:nth-child(3) {
    width: 20%;
  }
  
  .bookings-table th:nth-child(4) {
    width: 20%;
    text-align: left;
  }
  
  .student-details-container {
    float: left;
    text-align: left!important;
  }  

  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  